/* Modal overlay covers the entire viewport */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Box-style modal content with scroll */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh; /* Limit height to 80% of the viewport */
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
  border: 2px solid #ccc; /* Box border style */
  font-size: 18px;
}

/* Red close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgb(241, 92, 92); /* Red background */
  border: none;
  font-size: 20px;
  color: white; /* White 'X' or close icon color */
  cursor: pointer;
  width: 35px; /* Set width for the button */
  height: 35px; /* Set height for the button */
  border-radius: 50%; /* Makes the button round */
  display: flex;
  justify-content: center; /* Centers the 'X' horizontally */
  align-items: center; /* Centers the 'X' vertically */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.close-button:hover {
  background-color: darkred; /* Darker red on hover */
}


/* Adjust modal content for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    max-width: 90%;
    padding: 15px;
    max-height: 70vh; /* Reduce height for smaller screens */
  }
}
