.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #15e360;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000; /* Ensure it stays on top */
}

.whatsapp-button:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.whatsapp-icon {
  width: 35px;
  height: 35px;
}
