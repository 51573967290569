.header {
    background-image: url('../assets/1.png'); /* Updated path to your image */
    background-size: cover; /* Ensures the background covers the whole area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; 
    display: fixed;
    flex-direction: column;
    align-items: center;
    background-color: #ff2e00; 
    padding: 10px 0; 

 

}

.contact-info {
    display: flex;
    flex-direction: row; /* Default to row layout */
    justify-content: space-between; /* Space between items */
    padding: 7px; /* Adjust padding for better spacing */
    color: rgb(236, 20, 20);
    font-size: 17px;
    background-color: rgb(245, 245, 216); /* Yellow background */
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
   
}

@media (max-width: 480px) {
    .contact-info {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
    }

    .contact-info > div {
        margin-bottom: 7px; /* Add margin between rows */
        width: 100%; /* Full width on mobile */
    }
}

.brand-name {
    font-size: 30px; /* Adjust font size */
    color: rgb(251, 250, 223); /* Brand name color */
    font-weight: bold; /* Bold brand name */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px; /* Horizontal padding */
    background-color: #333; /* Dark background for nav */
}

.navbar ul {
    list-style: none; /* Remove default list styles */
    display: flex;
    gap: 25px; /* Space between nav items */
}

.navbar li a {
    size: 18px;
    color: yellow; /* Link color */
    text-decoration: none; /* Remove underline */
}

.navbar li a:hover {
    text-decoration: underline; /* Underline on hover */
}

.download-button {
    background-color: #ffc107; /* Button color */
    color: black; /* Button text color */
    padding: 10px 20px; /* Button padding */
    border: none; /* Remove border */
    border-radius: 10px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s;
     /* Smooth transition */
}

.download-button:hover {
    background-color: #e0a800; 
   /* Darker shade on hover */
}
