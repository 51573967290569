.contact-us {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

/* Contact details row */
.contact-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.contact-item {
  flex: 1;
  min-width: 250px; /* Ensures items have a minimum width */
  max-width: 300px; /* Ensures items do not grow too large */
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 10px; /* Adjusted to ensure space between items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-item h3 {
  color: #343a40;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-item p {
  font-size: 1.2rem;
  color: #495057;
}

/* Map and Directions styling */
.map {
  margin-top: 30px;
  text-align: center;
}

.map iframe {
  width: 100%;
  height: 350px;
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
}

.directions-link a {
  font-size: 1.2rem;
  color: red;
  text-decoration: none;
  font-weight: bold;
}

.directions-link a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  h2 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .contact-row {
    flex-direction: column;
    align-items: center; /* Center items in column direction */
  }

  .contact-item {
    max-width: 90%; /* Allow contact items to take up more space on mobile */
    margin: 10px 0; /* Adjust margin for vertical stacking */
  }

  .map iframe {
    height: 250px; /* Adjust map height for mobile screens */
  }
}
