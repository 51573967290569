/* Base styles for the entire page */
.safety-tips-page {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f7f7f7;
  color: #333;
}

/* Header section styling */
.safety-header {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffeb3b;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.safety-header h1 {
  font-size: 3em;
  color: #d32f2f; /* Bright red for emphasis */
  text-transform: uppercase;
  letter-spacing: 2px;
}

.safety-header p {
  font-size: 1.2em;
  color: #555;
  margin-top: 10px;
}

/* General safety guidelines section */
.general-tips {
  margin-bottom: 30px;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.general-tips h2 {
  font-size: 2.5em;
  color: #00796b; /* Teal color */
}

.general-tips ul {
  list-style: disc;
  padding-left: 20px;
}

.general-tips ul li {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.6;
  color: #004d40;
}

/* Do's and Don'ts section styling */
.dos-and-donts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.do-list, .dont-list {
  width: 48%;
  background-color: #fff3e0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.do-list h3, .dont-list h3 {
  font-size: 2em;
  color: #ef6c00; /* Orange color */
  margin-bottom: 15px;
}

.do-list ul, .dont-list ul {
  list-style: square;
  padding-left: 20px;
}

.do-list ul li, .dont-list ul li {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.6;
  color: #bf360c;
}

/* Image styling in Do's and Don'ts section */
.dos-donts-img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  margin-top: 20px;
}

/* Footer section styling */
.footer {
  text-align: center;
  padding: 20px 0;
  font-size: 0.9em;
  background-color: #b2ebf2;
  color: #004d40;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.footer p {
  margin: 5px 0;
}

/* Link styling */
a {
  color: #ff5722;
  text-decoration: none;
  font-weight: 600;
}

/* Hover effects */
a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Adjust header font size for mobile */
  .safety-header h1 {
    font-size: 2.2em;
  }

  .safety-header p {
    font-size: 1em;
  }

  /* General tips font size */
  .general-tips h2 {
    font-size: 2em;
  }

  .general-tips ul li {
    font-size: 1.1em;
  }

  /* Stack Do's and Don'ts list vertically on mobile */
  .dos-and-donts {
    flex-direction: column;
    align-items: center;
  }

  .do-list, .dont-list {
    width: 100%;
    margin-bottom: 20px;
  }

  /* Image adjusts to fit screen */
  .dos-donts-img {
    max-width: 100%;
  }

  /* Footer font size for mobile */
  .footer {
    font-size: 0.8em;
  }
}
