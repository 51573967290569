/* Categories.css */
.categories-container {
  
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    text-align: center;
  }
  
  .categories-container h2 {
    
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .categories-container p {
    
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .product-card {
    
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-image {
    
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .product-card h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Slick carousel dots */
  .slick-dots li button:before {
    font-size: 12px;
    color: #333;
  }
  
  .slick-dots li.slick-active button:before {
    color: #007bff;
  }
  
  /* Slick carousel arrows */
  .slick-prev, .slick-next {
    font-size: 0.8rem;
    color: #007bff;
  }
  
  .slick-prev:hover, .slick-next:hover {
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .categories-container h2 {
      font-size: 2rem;
    }
  
    .categories-container p {
      font-size: 1rem;
    }
  
    .product-card h3 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .categories-container h2 {
      font-size: 1.8rem;
    }
  
    .categories-container p {
      font-size: 0.9rem;
    }
  
    .product-card h3 {
      font-size: 0.9rem;
    }

    .Categories-title{
      
      font-size: 35px; /* Specify your desired font size */
      font-weight: bold; /* Make the text bold if needed */
      color: #333; /* You can specify a color as well */
      margin: 20px 0; /* Adjust margin as needed */
  }
  }
  