/* Prevent horizontal overflow on the body */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.footer {
  text-align: center;
  background-color: #343a40; /* Dark footer background */
  color: #ffffff; /* White text */
  width: 100%; /* Ensure it fits the screen width */
  font-size: 18px;
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #ffcc00;
  text-decoration: none;
  margin: 0 5px;
  font-size: 17px;
}

.footer a:hover {
  color: #ffffff;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .footer {
    font-size: 15px; /* Slightly smaller font size for mobile */
   
  }

  .footer a {
    font-size: 15px;
  }
}
