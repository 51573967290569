/* Overall Page Styling */
.checkout-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9; /* Light background for the page */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners for modern feel */
  }
  
  h1, h2 {
    text-align: center;
    color: #333; /* Darker font for contrast */
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ff5722; /* Accent color for the title */
  }
  
  h3 {
    font-size: 1.8rem;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Table Styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  thead {
    background-color: #ff5722;
    color: white;
  }
  
  th, td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  td {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Payment Options */
  .payment-options {
    text-align: center;
    margin-top: 20px;
  }
  
  .payment-options h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff5722;
  }
  .contact-options {
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
  }
  
  
  .payment-options button {
    background-color: #ff5722; 
    color: white; 
    padding: 12px 25px;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-options button:hover {
    background-color: #e64a19; /* Darker shade on hover */
  }
  
  .payment-options button:active {
    background-color: #d84315;
    transform: scale(0.98);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .checkout-page {
      padding: 15px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.5rem;
    }
  
    table th, table td {
      padding: 8px;
      font-size: 1rem;
    }
  
    .payment-options button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  