/* General styles that apply to both mobile and desktop */
.product-page {
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  width: 100%;
 
}

h1 {
  text-align: center;
  margin: 20px 0;
  font-size: 2.5rem;
  color: #333;
}

.header {
  background-color: #a39b7a;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  margin: 10px;
  text-align: center;
}

.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dfdfd5fd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  color: black;
  font-size: 1.54em;
  position: sticky;
  top: 0;
}

.proceed-to-pay{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(9, 212, 26);
  font-size: 2.0em;
  position: sticky;
  top: 0;
}

.cart-summary p {
  margin: 0 15px;
}

.table-responsive {
  margin: 20px auto;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.product-img {
  max-width: 50px;
  height: auto;
}

.quantity-input {
  width: 60px;
  padding: 5px;
}

.price-content-cell s {
  color: #777;
}

.price-content-cell .content-count {
  font-size: 0.7em;
  color: #555;
}
.search-container {
  margin: 20px 0;
  text-align: center;
}

.search-input {
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .cart-summary {
    flex-direction: column;
    font-size: 0.9em;
    padding: 10px;
  }

  .proceed-to-pay button {
    width: 100%;
    font-size: 18px;
  }

  table {
    font-size: 0.9em;
  }

  th, td {
    padding: 10px;
  }

  .quantity-input {
    width: 50px;
  }

  .product-img {
    max-width: 40px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  .cart-summary {
    padding: 10px;
    font-size: 0.8em;
  }

  table {
    font-size: 0.8em;
  }

  .product-img {
    max-width: 35px;
  }

  .quantity-input {
    width: 40px;
  }

  .proceed-to-pay button {
    font-size: 16px;
  }
}
