/* General Layout */
.home-page {
  padding: 20px;
}

.hero {
  background-image: url('../assets/DI.png'); /* Updated path to your image */
  background-size: cover; /* Ensures the background covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; 
  text-align: center;
  background-color: #f9f9f9;
  padding: 50px 0;
}
.highlight-text {
  color: yellow;
}
.h3-style {
  font-size: 1.2rem; /* Same as h3 font-size */
  font-weight: bold;   /* Same as h3 font-weight */
}
.highlight{
  font-size: 30px; /* Specify your desired font size */
  font-weight: bold; /* Make the text bold if needed */
  color: #333; /* You can specify a color as well */
  margin: 20px 0;

}
.kids-pack-title {
  font-size: 25px; /* Specify your desired font size */
  font-weight: bold; /* Make the text bold if needed */
  color: #333; /* You can specify a color as well */
  margin: 20px 0; /* Adjust margin as needed */
}
.Exclusive-pack{
  font-size: 30px; /* Specify your desired font size */
  font-weight: bold; /* Make the text bold if needed */
  color: #333; /* You can specify a color as well */
  margin: 20px 0; /* Adjust margin as needed */
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: yellow;
}

.cta-button {
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  
}

.featured-products, .packs-section {
  margin-top: 40px;
}

.featured-products h2, .packs-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.product-cards, .pack-cards {
  background-image: url('../assets/1.png'); /* Updated path to your image */
  background-size: cover; /* Ensures the background covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.product-card, .pack-card {
  background-image: url('../assets/4.png'); /* Updated path to your image */
  background-size: cover; /* Ensures the background covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; 
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  
}

.product-card img, .pack-card img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-button {
  background-color: #ff6600;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.product-button:hover {
  background-color: #e65c00;
}

/* Modal styling */
.modal {
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;  

  text-align: center;

}
.modal-background {
  background-image: url('../assets/10.png'); /* Update the path */
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  padding: 20px; /* Optional: add some padding */
  border-radius: 10px; /* Optional: adds rounded corners */
  color: white; /* Change text color if needed */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.modal h2 {
  margin-bottom: 20px;
}

/* Responsive Design */
.centered-ti {
  color: white; /* Makes the text white */
  text-align: center; /* Center-align the title */
  font-size: 2em; /* Optional: Adjust font size */
  margin-bottom: 20px; /* Optional: Add space below the title */
}
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .product-cards, .pack-cards {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .product-card, .pack-card {
    padding: 10px;
  }

  .product-button {
    padding: 8px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 7px 14px;
  }

  .product-cards, .pack-cards {
    grid-template-columns: 1fr;
  }

  .product-card, .pack-card {
    padding: 8px;
  }

  .product-button {
    padding: 6px;
  }

  .centered-title {
    text-align: left;
    background-color: white;
    
  }


  
}
